<template>
  <div class="items-center column">
    <div class="loadbar">
      <strong
        class="bar"
        :style="{ height: `${worklaodProgressPercentage}%` }"
      ></strong>
      <q-tooltip>
        <div>
          {{ totalAssigneTasks && `Tasks done: ${completedTasks.length} ` }}
        </div>
        <div>
          {{ totalAssigneTasks && `Total tasks: ${totalAssigneTasks}` }}
        </div>
      </q-tooltip>
    </div>
    <div class="q-mt-sm cursor-pointer" @click="setFilter(userWorkload.id)">
      <q-tooltip> {{ userWorkload.first }} {{ userWorkload.last }} </q-tooltip>
      <q-avatar size="30px" v-if="userWorkload.pic">
        <img :src="userWorkload.pic" :alt="userWorkload.first" />
      </q-avatar>
      <!-- <q-avatar size="30px" color="grey-4" text-color="grey-10" v-else>
        {{ userWorkload.first | firstChar }}{{ userWorkload.last | firstChar }}
      </q-avatar> -->
      <avatar v-else :size="30" :customStyle="{'font-size':'12px', 'font-weight':'600'}" :username="userWorkload.first+' '+userWorkload.last"></avatar>
    </div>
  </div>
</template>
<script>
import isThisWeek from "date-fns/isThisWeek";
import parseISO from "date-fns/parseISO";
import isToday from "date-fns/isToday";
import Avatar from 'vue-avatar';

export default {
  props: ["userWorkload", "myWorkDateFilter"],
  methods: {
    setFilter() {
      this.$router.push({
        name: "MemberView",
        params: {
          userId: this.userWorkload.id,
        },
      });
    },
  },
  components:{
    Avatar,
  },
  computed: {
    userTasks() {
      return this.userWorkload.task.filter((t) => t.card_id);
    },
    completedTasks() {
      return this.filteredTasks.length
        ? this.filteredTasks.filter((t) => t.completed)
        : [];
    },
    totalAssigneTasks() {
      return this.filteredTasks ? this.filteredTasks.length : [];
    },
    worklaodProgressPercentage() {
      if (this.totalAssigneTasks) {
        const percentage = parseInt(
          ((this.completedTasks.length / this.totalAssigneTasks) * 100).toFixed(
            0
          )
        );
        return percentage;
      }
      return 0;
    },
    filteredTasks() {
      if (this.myWorkDateFilter === "This week") {
        const filteredTasks = this.userTasks.filter((t) => {
          if (
            t.end_date &&
            isThisWeek(parseISO(t.end_date), { weekStartsOn: 1 })
          ) {
            return t;
          }
        });
        return filteredTasks;
      } else if (this.myWorkDateFilter === "Today") {
        const filteredTasks = this.userTasks.filter((t) => {
          if (
            t.end_date &&
            isToday(parseISO(t.end_date), { weekStartsOn: 1 })
          ) {
            return t;
          }
        });
        return filteredTasks;
      }
      return this.userTasks;
    },
  },
};
</script>
