<template>
  <div class="member-card q-pa-md relative-position">
    <!-- Member Avatar -->
    <div @click.stop="setFilter(member.id)" class="cursor-pointer">
      <div class="row items-center">
        <template>
          <q-avatar
            size="49px"
            color="grey-4"
            text-color="grey-10"
            class="cursor-pointer"
          >
            <img v-if="member.pic" :src="member.pic" :alt="member.first" />
            <!-- <span v-else>
              {{ member.first | firstChar }}{{ member.last | firstChar }}
            </span> -->
            <avatar v-else :size="49" :customStyle="{'font-size':'20px', 'font-weight':'600'}" :username="member.first+' '+member.last"></avatar>
            <q-tooltip>
              {{ member.fullname }}
            </q-tooltip>
          </q-avatar>
        </template>
        <span class="member-name q-ml-md">
          {{ member.id === user.id ? "You" : `${member.first} ${member.last}` }}
        </span>
      </div>
      <!-- Task stats -->
      <div
        class="row itme-center flex-no-wrap active-complete-task-count q-mt-md"
      >
        <div style="margin-right: 40px">
          <div>{{ totalAssigneTasks - completedTasks.length }}</div>
          <div class="text-label">Not done</div>
        </div>
        <div>
          <div>{{ completedTasks.length }}</div>
          <div class="text-label">Done</div>
        </div>
        <q-knob
          readonly
          show-value
          font-size="12px"
          :value="worklaodProgressPercentage"
          size="95px"
          :thickness="0.27"
          style="color: #007aff"
          track-color="grey-3"
          class="task-progress-knob"
        >
          <span class="task-complete-percentage">
            {{ worklaodProgressPercentage }}%
          </span>
        </q-knob>
      </div>
    </div>
    <q-separator size="5px" />
    <div class="hide-expansion-hover task-label-stats overflow-auto">
      <template v-for="label in groupByTaskLabel">
        <q-expansion-item :key="label.id" class="q-pa-none q-mb-md">
          <template v-slot:header>
            <div class="row flex-1 items-center">
              <q-avatar
                size="20px"
                :style="{ 'background-color': label.colorcode }"
                class="label-square q-mr-md"
              />
              <div class="label-text q-mr-sm">{{ label.name }}</div>
              <div class="label-task-count">{{ label.tasks.length }}</div>
            </div>
          </template>
          <div class="q-mt-sm">
            <div
              v-for="task in label.tasks"
              :key="task.id"
              class="row items-center q-py-sm flex-no-wrap"
            >
              <div style="z-index: 1" class="q-mr-sm">
                <div
                  v-if="task.completed"
                  @click="changeCompleteStatus(task)"
                  class="cursor-pointer"
                  style="min-width: 18px"
                >
                  <div class="row items-centern justify-center flex-no-wrap">
                    <div class="task-icon relative-position">
                      <q-icon
                        class="absolute text-bold"
                        :name="$icons.fasCheck"
                        style="color: #15d89a"
                        size="10px"
                      />
                    </div>
                  </div>
                  <q-tooltip> Mark Task InComplete </q-tooltip>
                </div>
                <div
                  v-else
                  @click="changeCompleteStatus(task)"
                  class="cursor-pointer notification-sidebar-outline-dot"
                >
                  <q-tooltip> Mark Task Complete </q-tooltip>
                </div>
              </div>
              <div
                class="cursor-pointer ellipses q-mr-xs"
                style="max-width: 260px"
                @click="edit(task, 'taskComment')"
              >
                <span class="dont-break-out task-title">
                  {{ task.title }}
                </span>
              </div>
            </div>
          </div>
        </q-expansion-item>
      </template>
    </div>
  </div>
</template>

<script>
const soundFile = require("@/assets/completeTaskSound.wav");

import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import cloneDeep from "lodash/cloneDeep";
import flatten from "lodash/flatten";
import isThisWeek from "date-fns/isThisWeek";
import parseISO from "date-fns/parseISO";
import isToday from "date-fns/isToday";
import { UpdateTaskMutation } from "@/gql";
import Avatar from 'vue-avatar';

export default {
  props: ["user", "member", "myWorkDateFilter", "editTaskDialogHandler"],
  data() {
    return {
      sound: null,
    };
  },
  mounted() {
    this.sound = new Audio(soundFile);
  },
  components:{
    Avatar,
  },
  methods: {
    async changeCompleteStatus(task) {
      this.sound.currentTime = 0;
      this.sound.play();
      const variables = {
        id: task.id,
        completed: !task.completed,
      };
      const taskEntity = this.$api.getEntity("task", task.id);
      taskEntity.completed = !taskEntity.completed;
      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    edit(task, isOpen = null) {
      const obj = {
        task,
        isOpen,
      };
      this.editTaskDialogHandler(obj);
    },
    setFilter(workloadId) {
      this.$router.push({
        name: "MemberView",
        params: {
          userId: workloadId,
        },
      });
    },
  },
  computed: {
    userTasks() {
      return this.member.task.filter((t) => t.card_id);
    },
    completedTasks() {
      return this.filteredTasks.length
        ? this.filteredTasks.filter((t) => t.completed)
        : [];
    },
    totalAssigneTasks() {
      return this.filteredTasks ? this.filteredTasks.length : [];
    },
    worklaodProgressPercentage() {
      if (this.totalAssigneTasks) {
        const percentage = parseInt(
          ((this.completedTasks.length / this.totalAssigneTasks) * 100).toFixed(
            0
          )
        );
        return percentage;
      }
      return 0;
    },
    flattenedLabels() {
      const labels = flatten(this.filteredTasks.map((t) => t.labels));
      return uniqBy(labels, "id");
    },
    groupByTaskLabel() {
      const tempLabelArr = cloneDeep(this.flattenedLabels);
      const group = tempLabelArr.map((label) => {
        this.filteredTasks.map((t) => {
          const labelIds = t.labels.map((l) => l.id);
          if (labelIds.includes(label.id)) {
            const labelTask = {
              id: t.id,
              board_id: t.board_id,
              card_id: t.card_id,
              completed: t.completed,
              isbookmarked: t.isbookmarked,
              title: t.title,
              myworks: t.myworks,
            };
            if (label.tasks && label.tasks.length) {
              label.tasks.push(labelTask);
              label.tasks = orderBy(label.tasks, "completed");
            } else {
              label.tasks = [labelTask];
            }
          }
        });
        return label;
      });

      return group;
    },
    filteredTasks() {
      if (this.myWorkDateFilter === "This week") {
        const filteredTasks = this.userTasks.filter((t) => {
          if (
            t.end_date &&
            isThisWeek(parseISO(t.end_date), { weekStartsOn: 1 })
          ) {
            return t;
          }
        });
        return filteredTasks;
      } else if (this.myWorkDateFilter === "Today") {
        const filteredTasks = this.userTasks.filter((t) => {
          if (
            t.end_date &&
            isToday(parseISO(t.end_date), { weekStartsOn: 1 })
          ) {
            return t;
          }
        });
        return filteredTasks;
      }
      return this.userTasks;
    },
  },
};
</script>
<style lang="scss" scoped>
.task-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #d5f7ec;
}
</style>
